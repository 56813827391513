export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  defaultLocale: 'en',
  messages: {
    en: {
      name: 'Arenachamp',
      moto: 'The smart scoreboard for any sport',
      description: 'Empowering sports institutions, clubs and schools of all sizes to elevate their scorekeeping game.',
    },
    fr: {
      name: 'Arenachamp',
      moto: 'Un tableau de bord simple et mobile pour n\'importe quel sport',
      description: 'Permet aux institutions sportives, clubs et écoles de toutes tailles de rehausser leur jeu de comptage de points.',
    },
    // German
    de: {
      name: 'Arenachamp',
      moto: 'Ein einfaches, mobilgesteuertes Scoreboard für jeden Sport',
      description: 'Ermöglicht es Sportinstitutionen, Clubs und Schulen aller Größen, ihr Punktezählung zu verbessern.',
    }
  }
}))